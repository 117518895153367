export default {
  namespaced: true,
  state: {
    nav_expanded: true,
  },
  mutations: {
    TOGGLE_NAV(state) {
      state.nav_expanded = !state.nav_expanded;
    },
    SET_NAV(state, expanded) {
      state.nav_expanded = expanded;
    },
  },
  actions: {
    toggle_navigation({ commit }) {
      commit("TOGGLE_NAV");
    },
    set_navigation_state({ commit }, { expanded }) {
      commit("SET_NAV", expanded);
    },
  },
};
