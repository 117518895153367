<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import "@/sass/global.scss";
export default {
  name: "App",
};
</script>

<style scoped></style>
