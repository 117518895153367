import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import(/* webpackChunkName: "main" */ "../views/Main.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home/Home.vue"),
      },
      {
        path: "/about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/about/About.vue"),
      },
      {
        path: "/discover",
        name: "Discover",
        component: () =>
          import(/* webpackChunkName: "discover" */ "../views/Discover.vue"),
      },
      {
        path: "/contact-us",
        name: "Contact",
        component: () =>
          import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
      },
      {
        path: "/received",
        name: "Received",
        component: () =>
          import(
            /* webpackChunkName: "contact-received" */ "../views/Received.vue"
          ),
      },
      {
        path: "/benefits",
        name: "Benefits",
        component: () =>
          import(/* webpackChunkName: "benefits" */ "../views/Benefits.vue"),
      },
      {
        path: "/get-started",
        name: "GetStarted",
        component: () =>
          import(
            /* webpackChunkName: "get-started" */ "../views/GetStarted.vue"
          ),
      },
    ],
  },
  {
    path: "/bristol-menu",
    name: "BristolMenu",
    component: () =>
      import(/* webpackChunkName: "bristol-menu" */ "../views/BristolMenu.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  next();
});

export default router;
